.faq .page-header {
  padding: 36px 15px;
  height: auto;
}

@media screen and (min-width: 768px) {
  .faq .page-header {
    padding: 80px 15px 0;
    height: 320px;
  }
}
.faq__anchor__block {
  padding: 38px 15px 30px;
}
.faq__anchor__block .inner {
  margin: 0 auto;
  padding: 0;
  max-width: 1100px;
}
.faq__anchor__block.achrBlock02 {
  background-color: #F1F6F9;
}
.faq__anchor__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.55;
  text-align: center;
}
.faq__anchor__list {
  display: flex;
  flex-wrap: wrap;
  margin: 16px -10px 0;
}
.faq__anchor__list > li {
  display: flex;
  align-items: flex-end;
  margin: 6px 0;
  padding: 7px 10px;
  width: 50%;
}
.faq__anchor__list > li span {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 5px;
}
.faq__anchor__list > li a {
  padding: 0 40px 6px 5px;
  height: auto;
  font-size: 14px;
  letter-spacing: 0.05em;
  transition: all 0.3s ease;
}
.faq__anchor__list > li:hover a {
  color: #0082D6;
}

@media screen and (min-width: 992px) {
  .faq__anchor__block {
    padding: 80px 15px 60px;
  }
  .faq__anchor__block .inner {
    margin: 0 auto;
    padding: 0;
  }
  .faq__anchor__block.achrBlock02 {
    padding: 80px 15px 70px;
  }
  .faq__anchor__title {
    font-size: 24px;
  }
  .faq__anchor__list {
    margin: 16px -20px 0;
  }
  .faq__anchor__list > li {
    margin: 20px 0;
    padding: 0 20px;
    width: 33.3333%;
    transition: all 0.3s ease;
  }
  .faq__anchor__list > li span {
    padding: 10px 20px 15px;
  }
  .faq__anchor__list > li a {
    padding: 0 35px 0 5px;
    width: 100%;
    height: 80px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.625;
  }
  .faq__anchor__list > li a:after {
    right: 5px;
  }
}
.faq__cont__block {
  padding: 60px 15px;
  position: relative;
}
.faq__cont__block::before, .faq__cont__block::after {
  content: "";
  display: block;
  background-repeat: repeat-y;
  background-position: 50% 0;
  background-size: 100% auto;
  position: absolute;
  z-index: 1;
}
.faq__cont__block::before {
  width: 120px;
  height: 100%;
  background-image: url(../images/faq/faq_bg_1.png);
  left: 0;
  top: 0;
  opacity: 0.5;
}
.faq__cont__block::after {
  width: 300px;
  height: 100%;
  background-image: url(../images/faq/faq_bg_2.png);
  right: -90px;
  top: 41.5%;
}
.faq__cont__block .inner {
  margin: 0 auto;
  padding: 0;
  max-width: 1100px;
  position: relative;
  z-index: 2;
}
.faq__cont__block.contBlock01 {
  background-color: #06548B;
}
.faq__cont__block.contBlock01 .faq__cont__title {
  color: #FFF;
}
.faq__cont__block.contBlock01 .faq__item__title {
  color: #FFF;
}
.faq__cont__block.contBlock01 .faq__anchor__backbtn a {
  color: #FFF;
}
.faq__cont__block.contBlock01 .faq__anchor__backbtn a::before {
  background-image: url(../images/common/icon_arw_btn2.svg);
}
.faq__cont__block.contBlock01 .faq__item dd.openAtFirstTimeOnly {
  display: block;
}
.faq__cont__block.contBlock02 {
  background-color: #F1F6F9;
}
.faq__cont__block.contBlock02 .faq__item dt {
  color: #0072BC;
}
.faq__cont__block.contBlock02 .faq__item dt::before {
  color: #0072BC;
}
.faq__cont__block.contBlock02 .faq__item dt.open {
  color: #FFF;
  background-color: #0072BC;
}
.faq__cont__block.contBlock02 .faq__item dt.open::before {
  color: #FFF;
}
.faq__cont__block.contBlock02 .faq__item dt.open::after {
  background-image: url(../images/common/icon_close.png);
}
.faq__cont__block.contBlock02 .faq__item dd.openAtFirstTimeOnly {
  display: block;
}
.faq__cont__title {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

@media screen and (min-width: 992px) {
  .faq__cont__block {
    padding: 123px 15px 80px;
  }
  .faq__cont__block::before {
    width: 618px;
    height: calc(100% + 100px);
    left: -200px;
    top: -100px;
  }
  .faq__cont__block::after {
    width: 434px;
    height: calc(100% + 120px);
    right: -50px;
    top: -120px;
  }
  .faq__cont__block .inner {
    margin: 0 auto;
    padding: 0;
  }
  .faq__cont__title {
    font-size: 43px;
    letter-spacing: 0.05em;
  }
}
.faq__item__title {
  margin-top: 30px;
  padding-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #CCC;
}

@media screen and (min-width: 992px) {
  .faq__item__title {
    margin-top: 80px;
    margin-bottom: 40px;
    padding-bottom: 20px;
    font-size: 24px;
    letter-spacing: 0.075em;
  }
}
.faq__item {
  margin: 18px 0;
  background-color: #FFF;
  border-radius: 6px;
  overflow: hidden;
}
.faq__item dt {
  padding: 12px 42px 12px 42px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.56;
  position: relative;
  cursor: pointer;
}
.faq__item dt::before {
  content: "Q.";
  display: block;
  color: #0072BC;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  left: 15px;
  top: 14px;
}
.faq__item dt::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../images/common/icon_open3.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  position: absolute;
  right: 15px;
  top: 50%;
  transition: all 0.3s ease;
  transform: translateY(-50%);
}
.faq__item dt.open {
  color: #0072BC;
  background-color: #f1f6f9;
}
.faq__item dt.open::after {
  background-image: url(../images/common/icon_close2.png);
}
.faq__item dd {
  display: none;
  padding: 15px 15px 15px 42px;
  position: relative;
}
.faq__item dd::before {
  content: "A.";
  display: block;
  color: #707070;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  left: 15px;
  top: 20px;
}
.faq__item__cont {
  margin: 20px 0 0 -27px;
  padding: 0;
  max-width: 900px;
}
.faq__item__cont .box {
  margin: 40px auto;
  font-size: 16px;
  max-width: 750px;
}
.faq__item__cont .img {
  margin-top: 14px;
}

@media screen and (min-width: 992px) {
  .faq__item {
    margin: 20px 0;
  }
  .faq__item dt {
    padding: 24px 52px 24px 75px;
    font-size: 20px;
  }
  .faq__item dt::before {
    font-size: 30px;
    left: 30px;
    top: 22px;
  }
  .faq__item dt::after {
    right: 30px;
  }
  .faq__item dd {
    padding: 24px 24px 76px 75px;
    font-size: 16px;
  }
  .faq__item dd::before {
    font-size: 30px;
    left: 30px;
    top: 24px;
  }
  .faq__item__cont {
    margin: 35px auto 0;
  }
  .faq__item__cont .box {
    margin: 60px auto;
  }
}
.faq__anchor__backbtn {
  text-align: right;
}
.faq__anchor__backbtn a {
  display: inline-block;
  padding-left: 25px;
  font-size: 12px;
  letter-spacing: 0;
  text-decoration: none;
  position: relative;
}
.faq__anchor__backbtn a::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url(../images/common/icon_arw_btn3.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}

@media screen and (min-width: 992px) {
  .faq__anchor__backbtn a {
    padding-left: 30px;
    font-size: 15px;
  }
  .faq__anchor__backbtn a::before {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
  }
}