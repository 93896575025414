.faq{

	.page-header{
		padding: 36px 15px;
		height: auto;
	}
}
@media screen and (min-width: 768px) {
	.faq{

		.page-header{
			padding: 80px 15px 0;
			height: 320px;
		}
	}
}

//-------------------------------------
.faq__anchor{

	&__block{
		padding: 38px 15px 30px;

		.inner{
			margin: 0 auto;
			padding: 0;
			max-width: 1100px;
		}

		&.achrBlock02{
			background-color: #F1F6F9;
		}
	}

	&__title{
		font-size: 18px;
		font-weight: 700;
		line-height: 1.55;
		text-align: center;
	}

	&__list{
		display: flex;
		flex-wrap: wrap;
		margin: 16px -10px 0;
	
		& > li{
			display: flex;
			align-items: flex-end;
			margin: 6px 0;
			padding: 7px 10px;
			width: 50%;

			span{
				display: flex;
				align-items: flex-end;
				width: 100%;
				height: 100%;
				padding: 5px;
			}

			a{
				padding: 0 40px 6px 5px;
				height: auto;
				font-size: 14px;
				letter-spacing: 0.05em;
				transition: all 0.3s ease;
			}

			&:hover{

				a{
					color: #0082D6;
				}
			}
		}
	}
}
@media screen and (min-width: 992px) {
	.faq__anchor{

		&__block{
			padding: 80px 15px 60px;
	
			.inner{
				margin: 0 auto;
				padding: 0;
			}

			&.achrBlock02{
				padding: 80px 15px 70px;
			}
		}

		&__title{
			font-size: 24px;
		}

		&__list{
			margin: 16px -20px 0;
	
			& > li{
				margin: 20px 0;
				padding: 0 20px;
				width: 33.3333%;
				transition: all 0.3s ease;

				span{
					padding: 10px 20px 15px;
				}

				a{
					padding: 0 35px 0 5px;
					width: 100%;
					height: 80px;
					font-size: 16px;
					font-weight: 500;
					line-height: 1.625;

					&:after{
						right: 5px;
					}
				}
			}
		}
	}
}






//-------------------------------------
.faq__cont{

	&__block{
		padding: 60px 15px;
		position: relative;
		
			&::before, &::after{
				content: "";
				display: block;
				background-repeat: repeat-y;
				background-position: 50% 0;
				background-size: 100% auto;
				position: absolute;
				z-index: 1;
			}
		
			&::before{
				width: 120px;
				height: 100%;
				background-image: url(../images/faq/faq_bg_1.png);
				left: 0;
				top: 0;
				opacity: 0.5;
			}
		
			&::after{
				width: 300px;
				height: 100%;
				background-image: url(../images/faq/faq_bg_2.png);
				right: -90px;
				top: 41.5%;
			}

		.inner{
			margin: 0 auto;
			padding: 0;
			max-width: 1100px;
			position: relative;
			z-index: 2;
		}

		&.contBlock01{
			background-color: #06548B;

			.faq__cont__title{
				color: #FFF;
			}

			.faq__item__title{
				color: #FFF;
			}

			.faq__anchor__backbtn{
			
				a{
					color: #FFF;

					&::before{
						background-image: url(../images/common/icon_arw_btn2.svg);
					}
				}
			}
			.faq__item{
				dd{
					&.openAtFirstTimeOnly {
						display: block;
					}
				}
			}
		}

		&.contBlock02{
			background-color: #F1F6F9;

			.faq__item{

				dt{
					color: #0072BC;

					&::before {
						color: #0072BC;
					}

					&.open {
						color: #FFF;
						background-color: #0072BC;

						&::before {
							color: #FFF;
						}
					}

					&.open {
						&::after {
							background-image: url(../images/common/icon_close.png);
						}
					}
				}

				dd{
					&.openAtFirstTimeOnly {
						display: block;
					}
				}
			}
		}
	}

	&__title{
		text-align: center;
		font-size: 18px;
		font-weight: 700;
	}
}
@media screen and (min-width: 992px) {
	.faq__cont{

		&__block{
			padding: 123px 15px 80px;

			&::before{
				width: 618px;
				height: calc( 100% + 100px );
				left: -200px;
				top: -100px;
			}
	
			&::after{
				width: 434px;
				height: calc( 100% + 120px );
				right: -50px;
				top: -120px;
			}

			.inner{
				margin: 0 auto;
				padding: 0;
			}

		}
	
		&__title{
			font-size: 43px;
			letter-spacing: 0.05em;
		}
	}
}


//-------------------------------------
.faq__item__title{
	margin-top: 30px;
	padding-bottom: 5px;
	font-size: 18px;
	font-weight: 500;
	border-bottom: 1px solid #CCC;
}
@media screen and (min-width: 992px) {
	.faq__item__title{
		margin-top: 80px;
		margin-bottom: 40px;
    padding-bottom: 20px;
		font-size: 24px;
		letter-spacing: 0.075em;
	}
}


//-------------------------------------
.faq__item{
	margin: 18px 0;
	background-color: #FFF;
	border-radius: 6px;
	overflow: hidden;

	dt {
		padding: 12px 42px 12px 42px;
		font-size: 16px;
		font-weight: 500;
		line-height: 1.56;
		position: relative;
		cursor: pointer;

		&::before {
			content: "Q.";
			display: block;
			color: #0072BC;
			font-size: 20px;
			font-weight: 700;
			line-height: 1;
			position: absolute;
			left: 15px;
			top: 14px;
		}

		&::after {
			content: "";
			display: block;
			width: 20px;
			height: 20px;
			background-image: url(../images/common/icon_open3.svg);
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
			position: absolute;
			right: 15px;
			top: 50%;
			transition: all 0.3s ease;
			transform: translateY(-50%);
		}

		&.open {
			color: #0072BC;
			background-color: #f1f6f9;
		}

		&.open {
			&::after {
				background-image: url(../images/common/icon_close2.png);
			}
		}
	}

	dd {
		display: none;
		padding: 15px 15px 15px 42px;
		position: relative;

		&::before {
			content: "A.";
			display: block;
			color: #707070;
			font-size: 20px;
			font-weight: 700;
			line-height: 1;
			position: absolute;
			left: 15px;
			top: 20px;
		}
	}

	&__cont{
		margin: 20px 0 0 -27px;
		padding: 0;
		max-width: 900px;

		.box{
			margin: 40px auto;
			font-size: 16px;
			max-width: 750px;
		}

		.img{
			margin-top: 14px;
		}
	}
}
@media screen and (min-width: 992px) {
	.faq__item{
		margin: 20px 0;

		dt {
			padding: 24px 52px 24px 75px;
			font-size: 20px;

			&::before {
				font-size: 30px;
				left: 30px;
				top: 22px;
			}

			&::after {
				right: 30px;
			}
		}

		dd {
			padding: 24px 24px 76px 75px;
			font-size: 16px;

			&::before {
				font-size: 30px;
				left: 30px;
				top: 24px;
			}
		}

		&__cont{
			margin: 35px auto 0;

			.box{
				margin: 60px auto;
			}
		}
	}
}







//-------------------------------------
.faq__anchor__backbtn{
	text-align: right;

	a{
		display: inline-block;
		padding-left: 25px;
		font-size: 12px;
		letter-spacing: 0;
		text-decoration: none;
		position: relative;

		&::before{
			content: "";
			display: block;
			width: 18px;
			height: 18px;
			background-image: url(../images/common/icon_arw_btn3.svg);
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%) rotate(-90deg);
		}
	}
}
@media screen and (min-width: 992px) {
	.faq__anchor__backbtn{

		a{
			padding-left: 30px;
			font-size: 15px;

			&::before{
				content: "";
				display: block;
				width: 24px;
				height: 24px;
			}
		}
	}
}